import Link from "next/link";

export default function Hero({ topTitle, title, subtitle, ctaUrl, ctaOffer }) {
  return (

    <section className="relative overflow-hidden font-['Inter']">
      <div className="bg-transparent" tabIndex={0} >
 <div className="relative pt-24 lg:pt-44 lg:pb-16">
          <div className="container px-4 mx-auto">
            <div className="relative flex flex-col justify-center items-center -mx-4">
              <div className="w-full max-w-3xl px-4 mb-12 md:mb-0 text-center flex flex-col items-center">
                <h1 className="mb-4 hero-top-title max-w-[33rem]">
                  Leading CKAN and data <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-300 to-orange-600 inline-block">experts</span><span className="inline-block whitespace-nowrap">.</span>
                  <br />
                  <span className="hero-title pt-2">
                    {title}
                  </span>
                </h1>
                <p className="mb-8 hero-subtitle max-w-2xl px-8 md:px-0">
                  {subtitle}
                </p>
                <div className="flex flex-col lg:flex-row justify-center gap-4  max-w-md">
                  <Link
                    href="/solutions"
                    className="btn-primary"
                  >
                    Solutions             <svg
                      className="ml-2 -mr-1 w-5 h-5"
                      fill="white"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                  <Link
                    href={ctaUrl}
                    className="btn-secondary"
                  >
                    {ctaOffer}
                  </Link>
                </div>
              </div>
              {/*<ChevronDoubleDownIcon className="pt-6 lg:pt-10 animate-shortfade opacity-0 text-orange-400 w-24 h-24" />*/}
            </div>
          </div>
        </div>
      </div>

      </section>

  );
}
