/* eslint import/no-default-export: off */
import Head from "next/head";
import { useEffect } from 'react';
import { useRouter } from "next/router";

export default function UnstyledLayout({ children }) {

 
  return (<>

  <div className="!overflow-x-hidden">{children}</div>
  </>
  );
}
