import { StarIcon } from "@heroicons/react/solid";
export default function Testimonials() {
  return (
    <section className="max-w-screen overflow-hidden ">
      <div className="relative w-full  m-10 px-4 sm:px-16 rounded-2xl mx-4">
        <div className="flex justify-start gap-1 mb-4">
          <StarIcon className="h-6 w-6 text-[#fb923c]" />
          <StarIcon className="h-6 w-6 text-[#fb923c]" />
          <StarIcon className="h-6 w-6 text-[#fb923c]" />
          <StarIcon className="h-6 w-6 text-[#fb923c]" />
          <StarIcon className="h-6 w-6 text-[#fb923c]" />
        </div>

        <p className="text-xl text-maingray leading-relaxed mb-4 font-['Inter'] text-start">
          I would like to take this opportunity to extend my heartfelt gratitude for the outstanding support and collaboration your team has offered over the past year. The agility, technical prowess, and steadfast dedication you have demonstrated are truly commendable. Your efforts have been integral to our successes, and I am deeply appreciative. I am hopeful that there will be opportunities for me to engage with future projects and contribute.

          Thank you once again for your exemplary contribution and for the positive spirit you bring to our work.
        </p>
        <div className="flex items-center gap-2 pt-2">

          <img
            className="w-8 h-8 md:h-12 md:w-12 object-cover"
            src="assets/images/pages/showcase/ssen-logo.png"
            alt="Shailesh Kumar"
            width={48}
            height={48}
          />
          <div className="main-text text-[#979797]">
            <p>Shailesh Kumar, Analytics and Data Architect</p>
            <p>Scottish & Southern Electricity Networks</p>
          </div>
        </div>

        <div className="rotate-180 absolute right-10 -bottom-8 sm:bottom-0 opacity-40">
          <img className="" src="assets/images/layout/quote.svg" alt="Quote" />
        </div>

      </div>

    </section>
  );
}
