import Image from "next/image";
import Marquee from "react-fast-marquee";

export default function SocialProof() {
  // create a list of logos for each organization as shown in the
  // design
  const logos = [
    {
      name: "The World Bank Group",
      src: "/assets/images/logos/socialproof/The_World_Bank_Group-300x63.webp",

    },
    {
      name: "Data.gov",
      src: "/assets/images/logos/socialproof/data.gov_-300x71.webp",

    },
    {
      name: "FedEx",
      src: "/assets/images/logos/socialproof/rsz_fedex-300x169.webp",

    },
    {
      name: "Gov.uk",
      src: "/assets/images/logos/socialproof/gov-uk.webp",

    },
    {
      name: "OECD",
      src: "/assets/images/logos/socialproof/OECD-300x76.webp",

    },
    {
      name: "HDX",
      src: "/assets/images/logos/socialproof/HDX.webp",

    },
    {
      name: "NextGEOSS",
      src: "/assets/images/logos/socialproof/nextgeoss.webp",

    },
    {
      name: "NHS",
      src: "/assets/images/logos/socialproof/NHS.webp",

    },
    {
      name: "US-Dept-of-Education",
      src: "/assets/images/logos/socialproof/US-Dept-of-Education.webp",

    },
    {
      name: "Data.europa.eu",
      src: "/assets/images/logos/socialproof/dataeuropaeu.webp",

    },
    {
      name: "OpenData.swiss",
      src: "/assets/images/logos/socialproof/Opendata-Swiss.webp",

    },
    {
      name: "Open Data Northern Ireland",
      src: "/assets/images/logos/socialproof/Open-Data-Northern-Ireland.webp",

    },
    {
      name: "Open Data DK",
      src: "/assets/images/logos/socialproof/OpenData-DK.webp",

    },
    {
      name: "SSEN",
      src: "/assets/images/logos/socialproof/SSEN_Mono@300x.png",

    },
  ];

  
  return (
    <section className="relative w-screen max-w-screen overflow-hidden" tabIndex={0}>
      <div className="flex justify-center">
        <div className="max-w-screen">
          <div className="">
            <div className="grayscale my-10 overflow-hidden">
              <Marquee speed={25} gradient={false}>
                {logos.map((logo) => (
                  <div className={`flex justify-center items-center px-8 object-center h-full`} key={logo.src}>
                    <Image
                      src={logo.src}
                      alt={logo.name}
                      className={`max-w-fit w-full h-auto object-center bypass-filter ${logo.name=="NHS" ? '!w-auto' : ``}`}
                      height={24}
                      width={120}
                    />
                  </div>
              )) 
              }
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
